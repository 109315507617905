const removeDiacritics = require("diacritics").remove;

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function titleCase(str) {
  if (!str) {
    return str;
  }
  return str
    .toLowerCase()
    .split(" ")
    .map(function(word) {
      if (word.length === 0) {
        return word;
      }
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

function stripNonSearchableCharacters(str) {
  if (!str) {
    return null;
  }

  return removeDiacritics(str.normalize().replace(/[\u0300-\u036f]/g, ""))
    .replace(/೭/g, "s")
    .replace(/[^a-zA-Z0-9]/g, " ")
    .toLowerCase();
}

function replaceWeirdCharacters(str) {
  if (!str) {
    return null;
  }

  return str
    .replace(/೭/g, "s")
    .replace(/舑|–/gu, "-")
    .replace(/'|񗐡|।|\t/g, "")
    .toLowerCase();
}

module.exports = {
  capitalizeFirstLetter,
  titleCase,
  stripNonSearchableCharacters,
  replaceWeirdCharacters
};
