import React, { Component } from "react";
import { Header, Container, Message } from "semantic-ui-react";

class DoNotPrintMessage extends Component {
  render() {
    return (
      <Message color="red" className="no-screen">
        <Header>Save Mother Earth!</Header>
        <Container>
          All bhajans are available offline if you download the "Bhajan Mala"
          app on the iOS App Store or Google Play Store. All bhajans are also
          freely available on BhajanMala.com.
        </Container>
      </Message>
    );
  }
}

export default DoNotPrintMessage;
