import React, { Component } from "react";
import { List } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

class BhajansList extends Component {
  render() {
    const bhajans = this.props.bhajans;
    if (!bhajans) {
      return;
    }
    return (
      <div style={{ backgroundColor: "white" }}>
        <List selection celled size="huge">
          {bhajans.map(this._renderBhajanItem.bind(this))}
        </List>
      </div>
    );
  }

  _onClickBhajan(node) {
    const location = `/nodes/${node.nodeId}`;
    this.props.history.push(location);
  }

  _renderBhajanItem(node) {
    const onClickItem = node => {
      return (event, elem) => this._onClickBhajan(node);
    };
    return (
      <List.Item key={node.nodeId} onClick={onClickItem(node)}>
        <List.Content>{node.data.name}</List.Content>
      </List.Item>
    );
  }
}

export default withRouter(BhajansList);
