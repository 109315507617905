import { TYPES } from "actions/index";
import { request, requestGet, requestDelete } from "network/request.js";

export function fetchAllNonLyrics(trimLanguageInfo = true) {
  const path = trimLanguageInfo
    ? "/api/graph/allNonLyrics"
    : "/api/graph/allNonLyrics?trimLanguageInfo=false";
  return dispatch => {
    requestGet(path).then(res => {
      dispatch(_receiveAllNonLyrics(res.data.nodeTypes, res.data.nodes));
    });
  };
}

export function saveNode(node, nodeId = 0) {
  const create = nodeId === 0;
  return dispatch => {
    return request({
      method: create ? "post" : "put",
      url: create ? `/api/graph/nodes` : `/api/graph/nodes/${nodeId}`,
      data: node
    }).then(res => {
      dispatch(_receiveNode(res.data));
      return res.data;
    });
  };
}

export function deleteNode(nodeId) {
  return dispatch => {
    return requestDelete(`/api/graph/nodes/${nodeId}`).then(res => {
      dispatch(_receiveDeleteNode(nodeId, res.data));
    });
  };
}

export function clearFetchedNodes() {
  return dispatch => {
    dispatch(_receiveAllNonLyrics([], []));
  };
}

function _receiveAllNonLyrics(nodeTypes, nodes) {
  return {
    type: TYPES.RECEIVE_ALL_NON_LYRICS,
    payload: {
      nodeTypes,
      nodes
    }
  };
}

function _receiveNode(node) {
  return {
    type: TYPES.RECEIVE_NODE,
    payload: node
  };
}

function _receiveDeleteNode(nodeId, updatedNodes) {
  return {
    type: TYPES.DELETE_NODE,
    payload: {
      nodeId,
      updatedNodes
    }
  };
}
