import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Search } from "semantic-ui-react";
import _ from "lodash";
import "components/bhajans/SearchBhajans.css";
import { history } from "util/history";
import { fetchAllNonLyrics } from "actions/nodes";

class SearchBhajans extends Component {
  componentWillMount() {
    this._resetComponent();
  }

  componentDidMount() {
    this.props.fetchAllNonLyrics();
  }

  render() {
    if (
      !this.props ||
      !this.props.graph ||
      !this.props.graph.nodes ||
      !this.props.graph.nodes.length
    ) {
      return null;
    }

    const results = this.state.results;
    const value = this.props.value;
    return (
      <Search
        category
        onSearchChange={_.debounce(this._onSearchChange.bind(this), 500, {
          leading: true
        })}
        onResultSelect={this._onResultSelect.bind(this)}
        results={results}
        placeholder="Search by Bhajan ID or name"
        value={value}
      />
    );
  }

  _resetComponent() {
    this.setState({
      results: [],
      value: ""
    });
  }

  _onSearchChange(e, { value }) {
    const nodeTypes = this.props.graph.nodeTypes;
    const lyricsNodeType = nodeTypes.find(
      nodeType => nodeType.name === "lyrics"
    );
    const nodes = this.props.graph.nodes.filter(
      node => node.nodeTypeId !== lyricsNodeType.nodeTypeId
    );

    const query = value ? value.toLowerCase() : value;
    const queryInt = parseInt(query, 10);

    let matchedNodes;
    if (!isNaN(queryInt)) {
      // searched for a bhajan ID
      matchedNodes = nodes.filter(node => node.nodeId === queryInt);
    }

    if (!matchedNodes || matchedNodes.length === 0) {
      const queryWords = query.split(" ");
      matchedNodes = nodes.filter(node => {
        let matches = !!node.data.name;
        for (const queryWord of queryWords) {
          matches =
            matches &&
            ((node.data.name && node.data.name.includes(queryWord)) ||
              (node.data.normalizedName &&
                node.data.normalizedName.includes(queryWord)) ||
              (node.data.enName && node.data.enName.includes(queryWord)));
        }
        return matches;
      });
    }

    const matchedNodesWithTitle = matchedNodes.map(node => {
      return {
        ...node,
        title: node.data.name
      };
    });

    const filteredResults = matchedNodesWithTitle.reduce((accum, node) => {
      const nodeType = nodeTypes.find(
        nodeType => nodeType.nodeTypeId === node.nodeTypeId
      );
      const nodeTypeName = nodeType.name;

      if (!accum[nodeTypeName]) {
        accum[nodeTypeName] = {
          name: nodeTypeName,
          results: []
        };
      }
      const results = accum[nodeTypeName].results;
      results.push(node);
      accum[nodeTypeName].results = results;
      return accum;
    }, {});

    this.setState({
      value,
      results: filteredResults
    });
  }

  _onResultSelect(event, { result }) {
    const nodeTypeId = result.nodeTypeId;
    const nodeType = this.props.graph.nodeTypes.find(
      nodeType => nodeType.nodeTypeId === nodeTypeId
    );
    if (["ragam", "talam", "composer", "deity"].includes(nodeType.name)) {
      history.push(`/nodeTypes/${nodeType.nodeTypeId}/nodes/${result.nodeId}`);
    } else {
      history.push(`/nodes/${result.nodeId}`);
    }
  }
}

function mapStateToProps(state) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(SearchBhajans);
