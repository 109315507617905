import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Container, List, Header, Divider } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchAllNonLyrics } from "actions/nodes";

class ViewNodesInNodeType extends Component {
  componentDidMount() {
    if (!this.props.nodes) {
      this.props.fetchAllNonLyrics(false);
    }
  }

  render() {
    if (
      !this.props ||
      !this.props.graph ||
      !this.props.graph.nodes ||
      !this.props.graph.nodeTypes
    ) {
      return null;
    }

    const nodeTypeId = parseInt(this.props.match.params.nodeTypeId, 10);
    const nodeType = this.props.graph.nodeTypes.find(
      nodeType => nodeType.nodeTypeId === nodeTypeId
    );
    if (!nodeType) {
      return null;
    }

    const edgeNodes = this.props.graph[nodeType.pluralName] || [];

    return (
      <Container>
        <Header as="h2">Edit {nodeType.pluralName}</Header>
        <Container textAlign="right">
          <Button
            positive
            onClick={() => {
              this._onAddNodeTypeTapped(nodeType);
            }}
          >
            Add {nodeType.name}
          </Button>
        </Container>
        <Divider />
        <div style={{ backgroundColor: "white" }}>
          <List selection celled size="huge">
            {edgeNodes.map(this._renderItem.bind(this))}
          </List>
        </div>
      </Container>
    );
  }

  _renderItem(node) {
    const onClickItem = node => {
      return (event, elem) => this._onClickItem(node);
    };
    return (
      <List.Item key={node.nodeId} link={true} onClick={onClickItem(node)}>
        <List.Content>{node.data.name}</List.Content>
      </List.Item>
    );
  }

  _onClickItem(node) {
    const location = `/nodes/${node.nodeId}/edit`;
    this.props.history.push(location);
  }

  _onAddNodeTypeTapped(nodeType) {
    const location = `/nodeTypes/${nodeType.nodeTypeId}/nodes/0/edit`;
    this.props.history.push(location);
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(ViewNodesInNodeType)
);
