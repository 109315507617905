import React, { Component } from "react";
import { Accordion as SemanticAccordion, Menu } from "semantic-ui-react";

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSectionIndex: this.props.selectedSectionIndex
    };
  }

  _renderSubmenu(section) {
    const createLinkHelper = this.props.createLinkHelper;
    return (
      <Menu.Menu>
        {section.items.map((item, index) => (
          <Menu.Item
            key={item.tagId}
            active={this.props.selectedItemIndex === index}
            link
          >
            {createLinkHelper ? createLinkHelper(section, item) : null}
          </Menu.Item>
        ))}
      </Menu.Menu>
    );
  }

  _onSectionClicked(index, event, section) {
    this.setState(prevState => ({
      selectedSectionIndex: index
    }));

    if (this.props.onSectionClick) {
      this.props.onSectionClick(section, index);
    }
  }

  render() {
    const sections = this.props.sections;
    if (!sections) {
      return null;
    }

    const onSectionClicked = this._onSectionClicked;
    const that = this;
    return (
      <SemanticAccordion as={Menu} fluid vertical>
        {sections.map((section, index) => {
          return (
            <Menu.Item
              active={this.state.selectedSectionIndex === index}
              key={section.name}
            >
              <SemanticAccordion.Title
                active={this.state.selectedSectionIndex === index}
                content={section.name}
                onClick={onSectionClicked.bind(that, index)}
              />
              <SemanticAccordion.Content
                active={this.state.selectedSectionIndex === index}
                content={this._renderSubmenu(section)}
              />
            </Menu.Item>
          );
        })}
      </SemanticAccordion>
    );
  }
}

export default Accordion;
