import { combineReducers } from "redux";
import UserSettingsReducer from "reducers/userSettings";
import TransliteratorReducer from "reducers/transliterator";
import LoginReducer from "reducers/login";
import BarebonesGraphReducer from "reducers/nodes";

const allReducers = combineReducers({
  userSettings: UserSettingsReducer,
  transliterated: TransliteratorReducer,
  auth: LoginReducer,
  graph: BarebonesGraphReducer
});

export default allReducers;
