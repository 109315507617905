import * as firebase from "firebase/app";
import "firebase/auth";
import { firebaseApp } from "components/firebase/firebase";
import { store } from "store/createStore";

/**
 * TRICKY:
 * This method returns a potentially stale version of the playlist that's being updated. This is to avoid another read towards firebase cost limits. We should really have a caching layer.
 */
async function addSelfAsViewerToPlaylistId(playlistId) {
  if (!playlistId) {
    console.log(`Playlist value was falsy! Found: ${{ playlistId }}`);
    return null;
  }

  const storeState = store.getState();
  const firebaseUser = storeState.auth.firebaseUser;
  if (!firebaseUser) {
    console.log(`Firebase user does not exist! Store state: ${storeState}`);
    return null;
  }

  const playlistRef = firebase
    .firestore()
    .collection("playlists")
    .doc(playlistId);
  const playlistDoc = await playlistRef.get();
  if (!playlistDoc.exists) {
    console.log(`Playlist with id ${playlistId} does not exist!`);
    return null;
  }

  const playlistData = playlistDoc.data();
  if (!isUserRoleOfPlaylist("viewers", playlistRef)) {
    playlistRef.update({
      "share.viewers": firebase.firestore.FieldValue.arrayUnion(
        firebaseUser.user.uid
      )
    });
  }

  return {
    ...playlistDoc.data(),
    id: playlistRef.id
  };
}

/**
 * TRICKY:
 * This method returns a potentially stale version of the playlist that's being updated. This is to avoid another read towards firebase cost limits. We should really have a caching layer.
 */
async function removeSelfAsViewerToPlaylistId(playlistId) {
  if (!playlistId) {
    console.log(`Playlist value was falsy! Found: ${{ playlistId }}`);
    return null;
  }

  const storeState = store.getState();
  const firebaseUser = storeState.auth.firebaseUser;
  if (!firebaseUser) {
    console.log(`Firebase user does not exist! Store state: ${storeState}`);
    return null;
  }

  const playlistRef = firebase
    .firestore()
    .collection("playlists")
    .doc(playlistId);
  const playlistDoc = await playlistRef.get();
  if (!playlistDoc.exists) {
    console.log(`Playlist with id ${playlistId} does not exist!`);
    return null;
  }

  const playlistData = playlistDoc.data();
  const isViewer = isUserRoleOfPlaylist("viewers", playlistData);
  const isCollaborator = isUserRoleOfPlaylist("collaborators", playlistData);
  const isAdmin = isUserRoleOfPlaylist("admins", playlistData);
  if (isAdmin) {
    // admins shouldn't remove, they should delete
    console.log("This user is an admin, cannot remove playlist.");
    return null;
  } else if (!isCollaborator && !isViewer) {
    console.log(
      "This user is neither a collaborator or a viewer, cannot remove playlist."
    );
    return null;
  }

  playlistRef.update({
    "share.viewers": firebase.firestore.FieldValue.arrayRemove(
      firebaseUser.user.uid
    ),
    "share.collaborators": firebase.firestore.FieldValue.arrayRemove(
      firebaseUser.user.uid
    )
  });

  return {
    ...playlistDoc.data(),
    id: playlistRef.id
  };
}

function isUserRoleOfPlaylist(roleName, playlistData) {
  if (!roleName || !playlistData) {
    return false;
  }

  const storeState = store.getState();
  const firebaseUser = storeState.auth.firebaseUser;
  const userId = firebaseUser.user.uid;
  return (
    playlistData &&
    playlistData.share &&
    playlistData.share[roleName] &&
    playlistData.share[roleName].includes(userId)
  );
}

export {
  addSelfAsViewerToPlaylistId,
  removeSelfAsViewerToPlaylistId,
  isUserRoleOfPlaylist
};
