import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Button,
  Dropdown,
  Message
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import BhajansList from "components/bhajans/BhajansList";
import * as firebase from "firebase/app";
import "firebase/auth";
import { firebaseApp } from "components/firebase/firebase";
import { fetchAllNonLyrics } from "actions/nodes";
import DeleteConfirm from "components/modals/deleteConfirm";
import {
  addSelfAsViewerToPlaylistId,
  removeSelfAsViewerToPlaylistId
} from "util/playlistUtil";

class ViewPlaylist extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevFirebaseUid =
      prevProps.auth &&
      prevProps.auth.firebaseUser &&
      prevProps.auth.firebaseUser.user &&
      prevProps.auth.firebaseUser.user.uid
        ? prevProps.auth.firebaseUser.user.uid
        : null;
    const currentFirebaseUid =
      this.props.auth &&
      this.props.auth.firebaseUser &&
      this.props.auth.firebaseUser.user &&
      this.props.auth.firebaseUser.user.uid
        ? this.props.auth.firebaseUser.user.uid
        : null;
    if (prevFirebaseUid !== currentFirebaseUid) {
      this._onAuthUpdated();
    }
  }

  componentDidMount() {
    if (this.props.graph.bhajans.length === 0) {
      this.props.fetchAllNonLyrics();
    }

    this._onAuthUpdated();
  }

  componentWillUnmount() {
    if (this.unsubscribePlaylistUpdates) {
      this.unsubscribePlaylistUpdates();
    }
  }

  render() {
    if (
      !this.state ||
      !this.state.playlist ||
      !this.props.graph ||
      !this.props.graph.bhajans ||
      this.props.graph.bhajans.length === 0
    ) {
      return null;
    }

    const playlist = this.state.playlist;
    const bhajanIds = playlist.bhajanIds.map(id => parseInt(id));
    const bhajans = bhajanIds
      .map(bhajanId =>
        this.props.graph.bhajans.find(b => b.nodeId === bhajanId)
      )
      .filter(b => !!b);

    const playlistId = this.props.match.params.playlistId;
    const firebaseUid =
      this.props.auth &&
      this.props.auth.firebaseUser &&
      this.props.auth.firebaseUser.user &&
      this.props.auth.firebaseUser.user.uid
        ? this.props.auth.firebaseUser.user.uid
        : null;
    const isViewer = playlist.share.viewers.includes(firebaseUid);
    const isAdmin = playlist.share.admins.includes(firebaseUid);

    return (
      <Container>
        <Header as="h1" textAlign="center">
          {playlist.name}
        </Header>

        {!isAdmin &&
          !isViewer && (
            <Message color="blue">
              <Message.Header>Add to Your Playlists?</Message.Header>
              <p>
                You can add this to your playlists by logging in with Facebook.
                After that, they'll show up everywhere (after you log in),
                including the{" "}
                <a href="https://itunes.apple.com/us/app/bhajan-mala/id583564306">
                  iOS
                </a>{" "}
                and{" "}
                <a href="https://play.google.com/store/apps/details?id=org.dycusa.bhajanmala">
                  Android
                </a>{" "}
                apps.
              </p>
            </Message>
          )}

        <div align="right">
          {isViewer &&
            !isAdmin && (
              <Button
                color="red"
                onClick={() => {
                  removeSelfAsViewerToPlaylistId(playlistId);
                }}
              >
                Remove from Your Playlists
              </Button>
            )}
          {!isViewer &&
            !isAdmin && (
              <Button
                color="green"
                onClick={() => {
                  if (firebaseUid) {
                    addSelfAsViewerToPlaylistId(playlistId);
                  } else {
                    alert("You must log in with Facebook first.");
                  }
                }}
              >
                Add to Your Playlists
              </Button>
            )}
          {isAdmin && (
            <Dropdown text="Actions" className="no-print">
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    this._onDeletePlaylistClicked();
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        <BhajansList
          bhajans={bhajans}
          editing={this.state.editing}
          onDeleteBhajanClicked={this._onDeleteBhajanClicked.bind(this)}
        />

        <DeleteConfirm
          content="Are you sure you want to delete this playlist? It cannot be undone and it will remove it from everyone else's devices too."
          open={this.state.isDeleteConfirmVisible}
          onCancelTapped={() => {
            this._closeDeleteModal();
          }}
          onDeleteTapped={() => {
            const playlistRef = firebase
              .firestore()
              .collection("playlists")
              .doc(playlistId);
            playlistRef.delete();

            this._closeDeleteModal();
            this.props.history.push("/playlists");
          }}
        />
      </Container>
    );
  }

  _onDeletePlaylistClicked() {
    const playlist = this.state.playlist;
    this.setState(prevState => {
      return {
        ...prevState,
        isDeleteConfirmVisible: true
      };
    });
  }

  _closeDeleteModal() {
    this.setState({
      isDeleteConfirmVisible: false
    });
  }

  _createEmptyPlaylist(firebaseUser) {
    if (!firebaseUser) {
      return null;
    }

    return {
      playlist: {
        name: "Playlist Name",
        bhajanIds: [],
        share: {
          admins: [firebaseUser.uid],
          viewers: [firebaseUser.uid],
          collaborators: []
        }
      }
    };
  }

  _onAuthUpdated() {
    if (this.unsubscribePlaylistUpdates) {
      this.unsubscribePlaylistUpdates();
      this.unsubscribePlaylistUpdates = null;
    }

    const playlistId = this.props.match.params.playlistId;
    const collectionRef = firebase.firestore().collection("playlists");
    const playlistRef = collectionRef.doc(playlistId);
    this.unsubscribePlaylistUpdates = playlistRef.onSnapshot(snapshot => {
      const newPlaylist = snapshot.data();
      if (newPlaylist) {
        this.setState({
          playlistNameEditing: false,
          playlist: newPlaylist
        });
      }
    });
  }

  _onDeleteBhajanClicked(bhajanNode) {
    console.log("Delete bhajan clicked:", { bhajanNode });
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(ViewPlaylist)
);
