import React, { Component } from "react";
import ViewBhajan from "components/main/ViewBhajan";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchAllNonLyrics } from "actions/nodes";
import ViewNodesInNodeType from "components/admin/viewNodesInNodeType";
import ViewBhajanMeaning from "components/main/ViewBhajanMeaning";

class ViewNode extends Component {
  componentDidMount() {
    this.props.fetchAllNonLyrics();
  }

  render() {
    if (!this.props.graph.nodes) {
      return null;
    }

    const nodeId = parseInt(this.props.match.params.nodeId, 10);
    if (isNaN(nodeId)) {
      return null;
    }

    const component = this._getComponent(nodeId);
    if (!component) {
      return null;
    }

    return React.createElement(component, {
      nodeId,
      ...this.props
    });
  }

  _getComponent(nodeId) {
    const node = this.props.graph.nodes.find(node => node.nodeId === nodeId);
    if (!node) {
      return null;
    }
    const nodeType = this.props.graph.nodeTypes.find(
      nodeType => nodeType.nodeTypeId === node.nodeTypeId
    );
    if (!nodeType) {
      return null;
    }

    if (nodeType.name === "bhajan") {
      return ViewBhajan;
    } else if (
      ["ragam", "talam", "composer", "deity"].includes(nodeType.name)
    ) {
      return ViewNodesInNodeType;
    } else if (nodeType.name === "meaning") {
      return ViewBhajanMeaning;
    }

    return null;
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ViewNode);
