import React, { Component } from "react";
import EditBasicNode from "components/main/EditBasicNode";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchAllNonLyrics } from "actions/nodes";

class EditNode extends Component {
  componentDidMount() {
    this.props.fetchAllNonLyrics(false);
  }

  render() {
    if (
      !this.props.graph.nodes ||
      !this.props.graph.nodes.length ||
      !this.props.graph.nodeTypes ||
      !this.props.graph.nodeTypes.length ||
      !this.props.auth ||
      !this.props.auth.apiUser ||
      !this.props.auth.apiUser.data ||
      !this.props.auth.apiUser.data.permissions ||
      !(
        this.props.auth.apiUser.data.permissions.includes("CREATE") ||
        this.props.auth.apiUser.data.permissions.includes("UPDATE")
      )
    ) {
      return null;
    }

    const nodeId = parseInt(this.props.match.params.nodeId, 10);
    if (isNaN(nodeId)) {
      return null;
    }

    const { nodeType, component } = this._getComponent(nodeId);
    if (!component) {
      return null;
    }

    return React.createElement(component, {
      nodeId,
      nodeTypeId: nodeType.nodeTypeId,
      ...this.props
    });
  }

  _getComponent(nodeId) {
    const node = this.props.graph.nodes.find(node => node.nodeId === nodeId);

    const nodeTypeIdParam = parseInt(this.props.match.params.nodeTypeId, 10);
    let nodeType;
    if (node) {
      nodeType = this.props.graph.nodeTypes.find(
        nodeType => nodeType.nodeTypeId === node.nodeTypeId
      );
    }

    if (!nodeType) {
      nodeType = this.props.graph.nodeTypes.find(
        nodeType => nodeType.nodeTypeId === nodeTypeIdParam
      );
    }

    if (!nodeType || (!node && nodeId !== 0)) {
      return null;
    }

    return {
      node,
      nodeType,
      component: EditBasicNode
    };
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(EditNode);
