import { createStore, applyMiddleware, compose } from "redux";
import allReducers from "reducers/index";
import thunk from "redux-thunk";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];
if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

export const store = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(...middlewares))
);
