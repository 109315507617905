import axios from "axios";
import * as Cookies from "js-cookie";
import * as COOKIES from "constants/cookies";

const DEFAULT_REQUEST_PARAMS = {
  platform: "web",
};

const pendingRequests = {};

function _requestKey(method, url) {
  return `${method}-${url}`;
}

function _pendingRequest(method, url) {
  const key = _requestKey(method, url);
  return !!pendingRequests[key];
}

function _markRequestPending(method, url, promise) {
  const key = _requestKey(method, url);
  pendingRequests[key] = promise;
}

function _clearRequestPending(method, url) {
  const key = _requestKey(method, url);
  pendingRequests[key] = null;
}

export function request(requestObj) {
  const obj = {
    ...requestObj,
    headers: {
      ...requestObj.headers,
      "Accept-Language": Cookies.get(COOKIES.selectedViewLanguage)
    },
    params: {
      ...requestObj.params,
      ...DEFAULT_REQUEST_PARAMS,
    },
  };
  
  const method = obj.method || "GET";
  const url = obj.url;

  const pendingRequest = _pendingRequest(method, url);
  let promise;
  if (pendingRequest) {
    console.log(
      `A request is already pending for ${method} ${url}. Adding onto that request.`
    );
    promise = pendingRequest;
  } else {
    promise = axios(obj);
  }

  _markRequestPending(method, url);
  promise.finally(res => {
    _clearRequestPending(method, url);
    return res;
  });
  return promise;
}

export function requestGet(url) {
  return request({
    method: "GET",
    url
  });
}

export function requestDelete(url) {
  return request({
    method: "DELETE",
    url
  });
}
