import { TYPES } from "actions/index";
import { allLanguages } from "constants/languages";
import * as Cookies from "js-cookie";
import * as COOKIES from "constants/cookies";

const defaultState = {
  selectedViewLanguage:
    Cookies.get(COOKIES.selectedViewLanguage) ||
    allLanguages.ENGLISH.languageCode
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TYPES.LANGUAGE_CHANGED:
      return {
        ...state,
        selectedViewLanguage: action.payload
      };
    default:
      return state;
  }
};
