import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Dropdown, Header, Table, Message } from "semantic-ui-react";
import "components/main/ViewBhajanMeaning.css";
import DoNotPrintMessage from "components/messages/doNotPrint";
import { fetchAllNonLyrics } from "actions/nodes";
import { requestGet } from "network/request";
import { withRouter } from "react-router-dom";
import { MarkdownEditor } from "../editors/MarkdownEditor";
import { allLanguages } from "../../constants/languages";
import { capitalizeFirstLetter } from "../../util/stringUtil";

class ViewBhajanMeaning extends Component {
  state = {
    fetchingLyricsNode: false
  };

  componentDidMount() {
    this.props.fetchAllNonLyrics();
  }

  render() {
    const meaningNodeId = this.props.nodeId;
    if (!meaningNodeId || isNaN(meaningNodeId)) {
      return null;
    }

    const meaningNode = this.props.graph.meanings.find(
      m => m.nodeId === meaningNodeId
    );
    const bhajanNode = this.props.graph.bhajans.find(b =>
      b.edges.find(e => e.name === "meaning" && e.nodeId === meaningNodeId)
    );
    if (!meaningNode || !bhajanNode) {
      return null;
    }

    const meaningLanguages = Object.keys(allLanguages)
      .map(l => allLanguages[l])
      .filter(
        languageObj =>
          meaningNode.data[`${languageObj.languageCode}Text`] &&
          meaningNode.data[`${languageObj.languageCode}Text`].trim().length
      );
    const preferredLanguageMeaningAvailable =
      meaningLanguages.filter(
        m => m.languageCode === this.props.userSettings.selectedViewLanguage
      ).length > 0;
    const selectedViewLanguageCode = this._getMeaningSelectedViewLanguageCode(
      preferredLanguageMeaningAvailable,
      meaningLanguages
    );
    const meaningKey = `${selectedViewLanguageCode}Text`;

    return (
      <Container text className="contentContainerWrapper">
        <Header as="h1" textAlign="center">
          {`Meaning: ${bhajanNode.data.name}`}
        </Header>
        <DoNotPrintMessage />
        {!preferredLanguageMeaningAvailable && (
          <Message color="yellow">
            <Header>Notice</Header>
            <Container>
              This bhajan's meaning is not available in your preferred language.
            </Container>
          </Message>
        )}
        <Container>
          {this._getViewLanguageSelector(
            preferredLanguageMeaningAvailable,
            meaningLanguages
          )}
        </Container>

        <MarkdownEditor
          key={meaningKey}
          value={meaningNode.data[meaningKey]}
          readOnly={true}
        />
      </Container>
    );
  }

  _getMeaningSelectedViewLanguageCode(
    preferredLanguageMeaningAvailable,
    meaningLanguages
  ) {
    if (this.state && this.state.selectedViewLanguage) {
      return this.state.selectedViewLanguage;
    }

    if (preferredLanguageMeaningAvailable) {
      return this.props.userSettings.selectedViewLanguage;
    } else {
      return meaningLanguages[0].languageCode;
    }
  }

  _getViewLanguageSelector(
    preferredLanguageMeaningAvailable,
    meaningLanguages
  ) {
    const options = meaningLanguages.map(m => {
      return {
        text: `${capitalizeFirstLetter(m.name)} Meaning`,
        value: m.languageCode
      };
    });

    const selectedLanguageCode = this._getMeaningSelectedViewLanguageCode(
      preferredLanguageMeaningAvailable,
      meaningLanguages
    );
    return (
      <Dropdown
        className={"no-print"}
        defaultValue={selectedLanguageCode}
        search
        selection
        options={options}
        onChange={this._onSelectedViewLanguageChanged.bind(this)}
      />
    );
  }

  _onSelectedViewLanguageChanged(event, data) {
    this.setState({
      selectedViewLanguage: data.value
    });
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(ViewBhajanMeaning)
);
