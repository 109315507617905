import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Dropdown, Header, Table } from "semantic-ui-react";
import "components/main/ViewBhajan.css";
import DoNotPrintMessage from "components/messages/doNotPrint";
import { fetchAllNonLyrics } from "actions/nodes";
import { requestGet } from "network/request";
import { withRouter } from "react-router-dom";

class ViewBhajan extends Component {
  state = {
    fetchingLyricsNode: false
  };

  componentDidMount() {
    this.props.fetchAllNonLyrics();
  }

  render() {
    const nodeId = this.props.nodeId;
    if (!nodeId || isNaN(nodeId)) {
      return null;
    }

    const nodes = this.props.graph.nodes;
    const node = nodes.find(node => node.nodeId === nodeId);
    if (!node) {
      return null;
    }

    const nodeTypes = this.props.graph.nodeTypes;
    const nodeType = nodeTypes.find(
      nodeType => nodeType.nodeTypeId === node.nodeTypeId
    );
    if (!nodeType) {
      return null;
    }

    const edgeNodes = node.edges
      .filter(edge => edge.name !== "lyrics" && edge.name !== "meaning")
      .reduce((accum, edge) => {
        const edgeNode = nodes.find(node => node.nodeId === edge.nodeId);
        if (edgeNode) {
          accum.push(edgeNode);
        }
        return accum;
      }, []);
    const getNodeTypeForNode = node => {
      return nodeTypes.find(
        nodeType => nodeType.nodeTypeId === node.nodeTypeId
      );
    };

    const canCreate =
      this.props.auth &&
      this.props.auth.apiUser &&
      this.props.auth.apiUser.data &&
      this.props.auth.apiUser.data.permissions.includes("CREATE");
    const canUpdate =
      this.props.auth &&
      this.props.auth.apiUser &&
      this.props.auth.apiUser.data &&
      this.props.auth.apiUser.data.permissions.includes("UPDATE");

    const ragamEdgeNode = edgeNodes.find(edgeNode => node.name === "ragam");

    const meaningEdge = node.edges.find(e => e.name === "meaning");
    const lyricsNode = this.state.lyricsNode;
    return (
      <Container text className="bhajanLyricsContainer">
        <Container textAlign="right">
          {meaningEdge && this._renderViewMoreMenu(meaningEdge.nodeId)}
          {(canCreate || canUpdate) && (
            <Dropdown text="Admin Actions" className="no-print">
              <Dropdown.Menu>
                {(canCreate || canUpdate) && (
                  <Dropdown.Item
                    onClick={() => {
                      this._onEditBhajanClicked(nodeId);
                    }}
                  >
                    Edit Bhajan
                  </Dropdown.Item>
                )}

                {(canCreate || canUpdate) && (
                  <Dropdown.Item
                    onClick={() => {
                      this._onEditMeaningClicked(nodeId);
                    }}
                  >
                    Edit Meaning
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Container>
        <Header as="h1" textAlign="center">
          {node.data.name}
        </Header>
        <DoNotPrintMessage />
        <Table definition compact collapsing>
          <Table.Body>
            {edgeNodes.map(edgeNode => {
              const edgeNodeType = getNodeTypeForNode(edgeNode);
              if (
                edgeNodeType.name === "album" ||
                edgeNodeType.name === "track" ||
                edgeNodeType.name === "audible"
              ) {
                return null;
              }

              return (
                <Table.Row key={edgeNode.nodeId}>
                  <Table.Cell>{edgeNodeType.name}</Table.Cell>
                  <Table.Cell>{edgeNode.data.name}</Table.Cell>
                </Table.Row>
              );
            })}
            {ragamEdgeNode &&
              ragamEdgeNode.data.arohanam && (
                <Table.Row>
                  <Table.Cell>Arohanam</Table.Cell>
                  <Table.Cell>{ragamEdgeNode.data.name}</Table.Cell>
                </Table.Row>
              )}
            {ragamEdgeNode &&
              ragamEdgeNode.data.avarohanam && (
                <Table.Row>
                  <Table.Cell>Avarohanam</Table.Cell>
                  <Table.Cell>{ragamEdgeNode.data.avarohanam}</Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>
        {lyricsNode && (
          <Container text>
            <pre className="bhajanLyrics">{lyricsNode.data.text}</pre>
          </Container>
        )}
      </Container>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.graph.nodes || this.state.fetchingLyricsNode) {
      return;
    }

    const nodeId = this.props.nodeId;
    const node = this.props.graph.nodes.find(node => node.nodeId === nodeId);
    if (!node) {
      return;
    }

    const lyricsEdge = node.edges.find(edge => edge.name === "lyrics");
    requestGet(`/api/graph/nodes/${lyricsEdge.nodeId}`).then(res => {
      this.setState({
        lyricsNode: res.data
      });
    });

    this.setState({
      fetchingLyricsNode: true
    });
  }

  _renderViewMoreMenu(meaningNodeId) {
    return (
      <Dropdown
        text="View More"
        className="no-print"
        style={{
          marginRight: "8px"
        }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              this.props.history.push(`/nodes/${meaningNodeId}`);
            }}
          >
            Meaning
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  _onEditBhajanClicked(nodeId) {
    this.props.history.push(`/nodes/${nodeId}/edit`);
  }

  _onEditMeaningClicked(nodeId) {
    this.props.history.push(`/nodes/${nodeId}/meanings/edit`);
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(ViewBhajan)
);
