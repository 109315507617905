import React, { Component } from "react";
import BhajansList from "components/bhajans/BhajansList";
import { Grid, Container } from "semantic-ui-react";
import Accordion from "components/accordion/accordion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import "components/main/BrowseBhajans.css";
import { fetchAllNonLyrics } from "actions/nodes";

class BrowseBhajans extends Component {
  componentDidMount() {
    this.props.fetchAllNonLyrics();
  }

  render() {
    if (!this.props.graph.nodeTypes || !this.props.graph.bhajans) {
      return null;
    }

    const nodeTypeId = parseInt(this.props.match.params.nodeTypeId, 10);
    const nodeId = parseInt(this.props.match.params.nodeId, 10);
    const nodeTypes = this.props.graph.nodeTypes;
    const nodes = this.props.graph.nodes;

    const sections = nodeTypes
      .filter(
        nodeType =>
          nodeType.name !== "bhajan" &&
          nodeType.name !== "lyrics" &&
          nodeType.name !== "audible" &&
          nodeType.name !== "track" &&
          nodeType.name !== "album"
      )
      .map(nodeType => {
        return {
          nodeType,
          name: nodeType.pluralName,
          items: nodes
            .filter(node => node.nodeTypeId === nodeType.nodeTypeId)
            .map(node => {
              return {
                node,
                name: node.data.name
              };
            })
        };
      });

    if (!sections.length) {
      return null;
    }

    if (isNaN(nodeTypeId) || isNaN(nodeId)) {
      return (
        <Redirect
          to={`/nodeTypes/${sections[0].nodeType.nodeTypeId}/nodes/${
            sections[0].items[0].node.nodeId
          }`}
        />
      );
    }

    const selectedSectionIndex = sections.findIndex(
      section => section.nodeType.nodeTypeId === nodeTypeId
    );
    const selectedSection = sections[selectedSectionIndex];
    const selectedItemIndex = selectedSection.items.findIndex(
      item => item.node.nodeId === nodeId
    );
    const bhajansForSelectedItem = this._getBhajansForNode(
      selectedSection.items[selectedItemIndex].node.nodeId
    );
    return (
      <Container className="contentContainer">
        <Grid stackable columns={2}>
          <Grid.Column width={4} key="master">
            <Accordion
              onSectionClick={this._onSectionClick.bind(this)}
              sections={sections}
              selectedSectionIndex={selectedSectionIndex}
              selectedItemIndex={selectedItemIndex}
              createLinkHelper={this._createAccordionLink.bind(this)}
            />
          </Grid.Column>
          <Grid.Column width={12} key="detail">
            <BhajansList bhajans={bhajansForSelectedItem} />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }

  _getBhajansForNode(nodeId) {
    return this.props.graph.bhajans.filter(bhajan => {
      return bhajan.edges.find(edge => edge.nodeId === nodeId);
    });
  }

  _createAccordionLink(section, item) {
    return (
      <Link
        to={`/nodeTypes/${section.nodeType.nodeTypeId}/nodes/${
          item.node.nodeId
        }`}
      >
        {item.name}{" "}
      </Link>
    );
  }

  _onSectionClick(section, index) {}
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(BrowseBhajans);
