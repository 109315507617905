import React, { Component } from "react";
import { Header, Segment, Image } from "semantic-ui-react";
import hhBanner from "images/hh-banner.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "components/main/Home.css";
import { fetchAllNonLyrics } from "actions/nodes";
import { Link } from "react-router-dom";
import SearchBhajans from "components/bhajans/SearchBhajans";

class Home extends Component {
  componentDidMount() {
    this.props.fetchAllNonLyrics();
  }

  render() {
    return (
      <div>
        <Segment inverted className="imageContainer">
          <Image src={hhBanner} centered className="icon" />
          <Header textAlign="center" inverted>
            {"\u266B Bhajan Mala \u266B"}
            <br />
            <br />
            <Header.Subheader>
              All of Sri Ganapathy Sachchidananda Swamiji's Bhajans, all in one
              place.
            </Header.Subheader>
          </Header>
          <Segment.Group>
            <SearchBhajans />
          </Segment.Group>
        </Segment>
        <Segment>
          <Header textAlign="center" as="h1">
            <p>
              <Link to="/browse">Browse Bhajans</Link>
            </p>
          </Header>
        </Segment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllNonLyrics
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(Home);
