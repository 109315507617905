import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  ContentState,
  KeyBindingUtil
} from "draft-js";
import "./MarkdownEditor.css";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";
const { hasCommandModifier } = KeyBindingUtil;

class MarkdownEditor extends Component {
  constructor(props) {
    super(props);
    const contentState = this.props.value
      ? stateFromMarkdown(this.props.value)
      : ContentState.createFromText("");
    this.state = {
      editorState: EditorState.createWithContent(contentState)
    };
    this.focus = () => this.refs.editor.focus();
    this.onChange = editorState => {
      if (!this.props.onChange) {
        return;
      }

      this.setState({ editorState });

      const markdown = stateToMarkdown(editorState.getCurrentContent());
      const plainText = editorState.getCurrentContent().getPlainText("\n");
      this.props.onChange({
        markdown,
        plainText
      });
    };
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const contentState = this.props.value
      ? stateFromMarkdown(this.props.value)
      : ContentState.createFromText("");
    this.setState({
      editorState: EditorState.createWithContent(contentState)
    });
  }

  _handleKeyCommand(command, editorState) {
    if (command === "quote") {
      const editorState = RichUtils.toggleBlockType(
        this.state.editorState,
        "blockquote"
      );
      this.setState({
        editorState
      });
      return true;
    }

    return false;
  }
  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 49 /* SPACE */ && hasCommandModifier(e)) {
      return "quote";
    }
    return getDefaultKeyBinding(e);
  }
  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }
  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }
  render() {
    const { editorState } = this.state;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = this.props.readOnly
      ? "RichEditor-editor"
      : "RichEditor-editor readwrite";
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }

    const rootClassNames = this.props.readOnly
      ? "RichEditor-Root"
      : "RichEditor-Root readwrite";

    return (
      <div className={rootClassNames}>
        {!this.props.readOnly && (
          <div>
            <BlockStyleControls
              editorState={editorState}
              onToggle={this.toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.toggleInlineStyle}
            />
          </div>
        )}
        <div className={className} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.onChange}
            placeholder={this.props.placeholder}
            ref="editor"
            spellCheck={true}
            readOnly={this.props.readOnly}
          />
        </div>
      </div>
    );
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  { label: "Heading", style: "header-two" },
  { label: "Sub-Heading", style: "header-three" },
  { label: "Unordered List", style: "unordered-list-item" },
  { label: "Ordered List", style: "ordered-list-item" },
  { label: "Quote", style: "blockquote" }
  // { label: "H1", style: "header-one" },
  // { label: "H4", style: "header-four" },
  // { label: "H5", style: "header-five" },
  // { label: "H6", style: "header-six" },
  // { label: "Quote", style: "code-block" }
];
const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
let INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" }
  // { label: "Monospace", style: "CODE" }
];
const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export { MarkdownEditor };
