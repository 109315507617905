import React, { Component } from "react";
import BhajansList from "components/bhajans/BhajansList";
import { Container, Segment, Button, Header, List } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FacebookButton from "components/buttons/FacebookButton";
import { login, logout } from "actions/login";
import * as firebase from "firebase/app";
import "firebase/auth";
import { firebaseApp } from "components/firebase/firebase";
import { store } from "store/createStore";
import { withRouter } from "react-router-dom";

const db = firebaseApp.firestore();

class BrowsePlaylists extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.auth !== this.props.auth) {
      this._onAuthUpdated();
    }
  }

  componentDidMount() {
    this._onAuthUpdated();
  }

  componentWillUnmount() {
    if (this.unsubscribeFromCollectionUpdates) {
      this.unsubscribeFromCollectionUpdates();
    }
  }

  render() {
    if (
      !this.props.auth ||
      !this.props.auth.apiUser ||
      !this.props.auth.firebaseUser
    ) {
      return (
        <Container>
          <Segment>
            <Header centered>
              You must log in with Facebook in order to see your playlists.
            </Header>
            <FacebookButton
              isLoggedIn={
                this.props.auth &&
                this.props.auth.apiUser &&
                this.props.auth.apiUser.data &&
                this.props.auth.apiUser.data.token &&
                this.props.auth.firebaseUser
              }
              onLogin={this.props.login.bind(this)}
              onLogout={this.props.logout.bind(this)}
            />
          </Segment>
        </Container>
      );
    }

    if (!this.state || !this.state.playlists) {
      return (
        <Container>
          <Segment>
            <Header centered>You have no playlists.</Header>
          </Segment>
        </Container>
      );
    }

    return (
      <Container>
        <Header as="h1" textAlign="center">
          Playlists
        </Header>

        <List selection celled size="huge" style={{ backgroundColor: "white" }}>
          {this.state.playlists.length > 0 &&
            this.state.playlists.map(playlist => {
              return (
                <List.Item
                  key={playlist.id}
                  onClick={() => {
                    this._onPlaylistClicked(playlist.id);
                  }}
                >
                  <List.Content>{playlist.name}</List.Content>
                </List.Item>
              );
            })}
        </List>
      </Container>
    );
  }

  _onCollectionUpdate(snapshot) {
    const playlists = [];

    snapshot.forEach(doc => {
      playlists.push({
        id: doc.id,
        ...doc.data()
      });
    });

    this.setState({
      playlists
    });
  }

  _onAuthUpdated() {
    if (this.unsubscribeFromCollectionUpdates) {
      this.unsubscribeFromCollectionUpdates();
      this.unsubscribeFromCollectionUpdates = null;
    }

    const firebaseUser =
      this.props.auth && this.props.auth.firebaseUser
        ? this.props.auth.firebaseUser
        : null;
    if (!firebaseUser) {
      this.setState({
        playlists: []
      });
      return;
    }

    const collectionRef =
      firebaseUser && firebaseUser.user && firebaseUser.user.uid
        ? firebase
            .firestore()
            .collection("playlists")
            .where("share.viewers", "array-contains", firebaseUser.user.uid)
        : null;
    this.setState({
      playlists: []
    });

    if (!collectionRef) {
      return;
    }

    this.unsubscribeFromCollectionUpdates = collectionRef.onSnapshot(
      this._onCollectionUpdate.bind(this)
    );
  }

  _onPlaylistClicked(playlistId) {
    const location = `/playlists/${playlistId}`;
    this.props.history.push(location);
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login,
      logout
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(BrowsePlaylists)
);
