import { TYPES } from "actions/index";

export default (state = null, action) => {
  switch (action.type) {
    case TYPES.RECEIVE_FIREBASE_USER:
      return {
        ...state,
        firebaseUser: action.payload
      };
    case TYPES.RECEIVE_PROFILE:
      return {
        ...state,
        apiUser: _parseProfile(action.payload)
      };
    default:
      return state;
  }
};

function _parseProfile(profile) {
  if (!profile) {
    return {
      data: {
        permissions: []
      }
    };
  } else if (!profile.data) {
    return {
      ...profile,
      data: {
        permissions: []
      }
    };
  } else {
    const permissions = profile.data.permissions
      ? profile.data.permissions.split(",")
      : [];
    profile.data.permissions = permissions;
    return profile;
  }
}
