import { TYPES } from "actions/index";
import update from "react-addons-update";
import _ from "lodash";
import { allLanguages } from "constants/languages";
import * as Cookies from "js-cookie";
import * as COOKIES from "constants/cookies";
import { stripNonSearchableCharacters } from "util/stringUtil";

const DEFAULT_STATE = {
  nodeTypes: [],
  nodes: [],
  bhajans: [],
  ragams: [],
  talams: [],
  composers: [],
  deities: [],
  meanings: []
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TYPES.RECEIVE_ALL_NON_LYRICS:
      return _groupNodes(action.payload.nodeTypes, action.payload.nodes);
    case TYPES.RECEIVE_NODES:
      return action.payload;
    case TYPES.RECEIVE_NODE:
      return _updateSingleNode(state, action.payload);
    case TYPES.DELETE_NODE:
      return _deleteNode(
        state,
        action.payload.nodeId,
        action.payload.updatedNodes
      );
    default:
      return state;
  }
};

function _groupNodes(nodeTypes, nodes) {
  const nodesWithName = _autoAddName(nodes);
  const nodeTypesByNodeTypeId = _.keyBy(nodeTypes, "nodeTypeId");
  const sortedNodes = _.sortBy(nodesWithName, "data.normalizedName");
  const nodesByType = sortedNodes.reduce((accum, node) => {
    const nodeType = nodeTypesByNodeTypeId[node.nodeTypeId];
    const keyName = nodeType.pluralName;
    if (!accum[keyName]) {
      accum[keyName] = [];
    }

    accum[keyName].push(node);
    return accum;
  }, {});
  nodesByType.nodeTypes = nodeTypes;
  nodesByType.nodes = sortedNodes;
  return nodesByType;
}

function _autoAddName(nodes) {
  // TRICKY: we can't get another reducer from inside this reducer, so we duplicated this logic :(
  const selectedViewLanguage =
    Cookies.get(COOKIES.selectedViewLanguage) || allLanguages.ENGLISH.name;
  return nodes.map(node => {
    if (node.data.teName && !node.data.name) {
      node.data.name = node.data[`${selectedViewLanguage}Name`];

      if (node.data.name) {
        node.data.normalizedName = stripNonSearchableCharacters(node.data.name);
      }
    }
    return node;
  });
}

function _updateSingleNode(nodes, node) {
  const index = nodes.findIndex(n => n.nodeId === node.nodeId);
  if (index >= 0) {
    return update(nodes, {
      $splice: [[index, 1, node]]
    });
  } else {
    return update(node, {
      $push: [node]
    });
  }
}

function _deleteNode(nodes, nodeId, updatedNodes) {
  const nodeIdSet = new Set(updatedNodes.map(node => node.nodeId));
  nodeIdSet.add(nodeId);
  const cleanedNodes = nodes.filter(node => nodeIdSet.has(node.nodeId));
  for (const node of updatedNodes) {
    cleanedNodes.push(node);
  }
  return cleanedNodes;
}
