import React, { Component } from "react";
import { Confirm } from "semantic-ui-react";

class DeleteConfirm extends Component {
  render() {
    return (
      <div>
        <Confirm
          content={this.props.content}
          open={this.props.open}
          onCancel={this._onCancelTapped.bind(this)}
          onConfirm={this._onDeleteTapped.bind(this)}
        />
      </div>
    );
  }

  _onDeleteTapped() {
    if (this.props.onDeleteTapped) {
      this.props.onDeleteTapped();
    }
  }

  _onCancelTapped() {
    if (this.props.onCancelTapped) {
      this.props.onCancelTapped();
    }
  }
}

export default DeleteConfirm;
