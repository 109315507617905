const ALL_LANGUAGES = {
  TELUGU: {
    name: "telugu",
    languageCode: "te"
  },
  ENGLISH: {
    name: "english",
    languageCode: "en"
  },
  KANNADA: {
    name: "kannada",
    languageCode: "kn"
  },
  TAMIL: {
    name: "tamil",
    languageCode: "ta"
  },
  HINDI: {
    name: "hindi",
    languageCode: "hi"
  },
  MALAYALAM: {
    name: "malayalam",
    languageCode: "ml"
  },
  ORIYA: {
    name: "oriya",
    languageCode: "or"
  },
  BENGALI: {
    name: "bengali",
    languageCode: "bn"
  },
  PUNJABI: {
    name: "punjabi",
    languageCode: "pa"
  },
  GUJARATI: {
    name: "gujarati",
    languageCode: "gu"
  }
};

const languages = Object.keys(ALL_LANGUAGES).map(l => {
  return ALL_LANGUAGES[l].name;
});

const languageCodes = Object.keys(ALL_LANGUAGES).map(l => {
  return ALL_LANGUAGES[l].languageCode;
});

function languageForLanguageCode(languageCode) {
  const index = languageCodes.findIndex(code => code === languageCode);
  if (index < 0) {
    return null;
  }

  return index < 0 ? null : languages[index];
}

function languageCodeForLanguage(language) {
  const index = languages.findIndex(lang => lang === language);
  if (index < 0) {
    return null;
  }

  return index < 0 ? null : languageCodes[index];
}

function languageObjForLanguageName(languageName) {
  const uppercaseLanguage = languageName.toUpperCase();
  return ALL_LANGUAGES[languageName];
}

module.exports = {
  allLanguages: ALL_LANGUAGES,
  languages,
  languageCodes,
  languageForLanguageCode,
  languageCodeForLanguage,
  languageObjForLanguageName
};
