import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Form,
  Button,
  Confirm,
  Input,
  Table,
  Divider,
  Segment,
  Message
} from "semantic-ui-react";
import { requestGet, request } from "network/request";
import { withRouter } from "react-router-dom";

const initialState = {
  node: null,
  targetNode: null,
  nodeId: null,
  targetNodeId: null,
  mode: null,
  confirmDeleteOpen: false
};

class ConsolidateNode extends Component {
  state = Object.assign({}, initialState);

  render() {
    const nodeTypesMismatched =
      this.state.node &&
      this.state.targetNode &&
      this.state.targetNode.nodeTypeId !== this.state.node.nodeTypeId;
    const nodesAreSame =
      this.state.node &&
      this.state.targetNode &&
      this.state.node.nodeId === this.state.targetNode.nodeId;
    const cannotConsolidate = nodesAreSame || nodeTypesMismatched;
    const showsPreview = !this.state.mode || cannotConsolidate;
    return (
      <Container text className="bhajanLyricsContainer">
        <Header as="h1" textAlign="center">
          {"Consolidate Node"}
        </Header>

        <Container>
          <Form>
            <Form.Field>
              <Input
                placeholder={"Source Node (Will be deleted)"}
                value={this.state.nodeId || ""}
                onChange={(event, { value }) => {
                  return this._onInputTextValueChanged("node", value);
                }}
                className="autoWidth"
              />
            </Form.Field>
            <Form.Field>
              <Input
                placeholder={"Target Node (Will keep)"}
                value={this.state.targetNodeId || ""}
                onChange={(event, { value }) => {
                  return this._onInputTextValueChanged("targetNode", value);
                }}
                className="autoWidth"
              />
            </Form.Field>
          </Form>
        </Container>

        <Divider hidden={true} />

        {this.state.mode === "preview" &&
          !this.state.targetNode && (
            <Container>{"Loading Preview..."}</Container>
          )}

        {this.state.mode === "preview" &&
          this.state.node &&
          this.state.targetNode && (
            <Container>
              {cannotConsolidate && (
                <Segment basic>
                  <Message error>
                    <Message.Header>Node Types Mismatch</Message.Header>
                    <p>The node types of source and target do not match.</p>
                  </Message>
                </Segment>
              )}

              <Table definition compact collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="2">
                      From Node (Will Delete)
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row key="nodeId">
                    <Table.Cell>{"Node ID"}</Table.Cell>
                    <Table.Cell>{this.state.node.nodeId}</Table.Cell>
                  </Table.Row>
                  <Table.Row key="nodeTypeId">
                    <Table.Cell>{"Node Type ID"}</Table.Cell>
                    <Table.Cell>{this.state.node.nodeTypeId}</Table.Cell>
                  </Table.Row>
                  <Table.Row key="data">
                    <Table.Cell>{"Data"}</Table.Cell>
                    <Table.Cell>
                      <pre>{JSON.stringify(this.state.node.data, null, 2)}</pre>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Table definition compact collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="2">
                      To Target Node (Will Keep)
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row key="nodeId">
                    <Table.Cell>{"Node ID"}</Table.Cell>
                    <Table.Cell>{this.state.targetNode.nodeId}</Table.Cell>
                  </Table.Row>
                  <Table.Row key="nodeTypeId">
                    <Table.Cell>{"Node Type ID"}</Table.Cell>
                    <Table.Cell>{this.state.targetNode.nodeTypeId}</Table.Cell>
                  </Table.Row>
                  <Table.Row key="data">
                    <Table.Cell>{"Data"}</Table.Cell>
                    <Table.Cell>
                      <pre>
                        {JSON.stringify(this.state.targetNode.data, null, 2)}
                      </pre>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Container>
          )}

        <Divider />

        <Container textAlign="right">
          {showsPreview && (
            <Button
              type="submit"
              onClick={() => {
                this.onPreviewTapped();
              }}
            >
              Preview >>
            </Button>
          )}

          {!showsPreview && (
            <Button
              type="submit"
              onClick={() => {
                this.onStartOverTapped();
              }}
            >
              {"<< Start Over"}
            </Button>
          )}

          {!showsPreview && (
            <Button
              type="submit"
              negative
              onClick={() => {
                this.onConsolidateTapped();
              }}
            >
              Consolidate
            </Button>
          )}
        </Container>

        <Confirm
          content={
            "Are you sure you want to CONSOLIDATE this? This CANNOT BE UNDONE."
          }
          open={this.state.confirmDeleteOpen}
          onCancel={() => {
            this._closeConfirmDelete();
          }}
          onConfirm={() => {
            this._onConfirmDelete();
          }}
        />
      </Container>
    );
  }

  _onInputTextValueChanged(name, value) {
    const propName = `${name}Id`;
    if (!value) {
      this.setState({
        [propName]: null
      });
      return;
    }
    if (isNaN(parseInt(value, 10))) {
      return false;
    }

    this.setState({
      [propName]: value
    });
  }

  onStartOverTapped() {
    this.setState(initialState);
  }

  onPreviewTapped() {
    if (!this.state.nodeId || !this.state.targetNodeId) {
      return;
    }

    const nodeId = parseInt(this.state.nodeId, 10);
    const targetNodeId = parseInt(this.state.targetNodeId, 10);
    if (isNaN(nodeId) || isNaN(targetNodeId)) {
      return;
    }

    requestGet(`/api/graph/nodes/${nodeId}`).then(res => {
      this.setState({
        node: res.data
      });
    });

    requestGet(`/api/graph/nodes/${targetNodeId}`).then(res => {
      this.setState({
        targetNode: res.data
      });
    });

    this.setState({
      mode: "preview"
    });
  }

  onConsolidateTapped() {
    this.setState({
      confirmDeleteOpen: true
    });
  }

  _onConfirmDelete() {
    const nodeId = parseInt(this.state.nodeId, 10);
    const targetNodeId = parseInt(this.state.targetNodeId, 10);

    if (isNaN(nodeId) || isNaN(targetNodeId)) {
      return;
    }

    request({
      method: "PUT",
      url: `/api/graph/nodes/${nodeId}/consolidate?targetNodeId=${targetNodeId}`
    })
      .then(res => {
        alert("Done!");
        this.onStartOverTapped();
      })
      .catch(err => {
        alert(`Error: ${err}`);
      });

    this._closeConfirmDelete();
  }

  _closeConfirmDelete() {
    this.setState({
      confirmDeleteOpen: false
    });
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(ConsolidateNode)
);
