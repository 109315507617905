import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Header, Form, Button } from "semantic-ui-react";
import { requestGet, request } from "../../network/request";
import {
  languages,
  languageObjForLanguageName,
  languageCodeForLanguage
} from "constants/languages";
import { MarkdownEditor } from "../editors/MarkdownEditor";

class EditMarkdownNode extends Component {
  async componentDidMount() {
    if (this.state && this.state.bhajanNode && this.state.meaningNode) {
      return;
    }

    const bhajanNodeId = parseInt(this.props.match.params.nodeId, 10);
    this._fetchNodeFromRemote(bhajanNodeId).then(res => {
      const bhajanNode = res.data;
      if (bhajanNode.nodeTypeId !== 1) {
        throw new Error("Invalid node");
      }

      this.setState({
        bhajanNode
      });

      const meaningEdge = bhajanNode.edges.find(e => e.name === "meaning");
      if (meaningEdge) {
        this._fetchNodeFromRemote(meaningEdge.nodeId).then(meaningRes => {
          this.setState({
            meaningNode: meaningRes.data
          });
        });
      } else {
        this.setState({
          meaningNode: {
            nodeId: 0,
            nodeTypeId: 10, // meaning
            data: {},
            edges: []
          }
        });
      }
    });
  }

  render() {
    if (!this.state || !this.state.bhajanNode || !this.state.meaningNode) {
      return null;
    }

    return (
      <Container text className="bhajanLyricsContainer">
        <Header as="h1" textAlign="center">
          {`Meaning: ${
            this.state.bhajanNode.data[
              `${this.props.userSettings.selectedViewLanguage}Name`
            ]
          }`}
        </Header>
        <Form>{this._renderMarkdownFieldsForAllLanguages()}</Form>
        {this._renderSaveButton()}
      </Container>
    );
  }

  _renderMarkdownFieldsForAllLanguages() {
    return languages.map(languageName => {
      const languageObj = languageObjForLanguageName(
        languageName.toUpperCase()
      );
      return this._renderMarkdownFieldsForLanguage(languageObj);
    });
  }

  _renderMarkdownFieldsForLanguage(languageObj) {
    return (
      <div
        style={{
          marginBottom: "70px"
        }}
        key={languageObj.languageCode}
      >
        <h2>{`${languageObj.name}:`}</h2>
        <MarkdownEditor
          value={
            this.state.meaningNode.data[`${languageObj.languageCode}Text`] || ""
          }
          onChange={({ markdown, plainText }) => {
            this.state.meaningNode.data[
              `${languageObj.languageCode}Text`
            ] = markdown;
          }}
        />
      </div>
    );
  }

  _fetchNodeFromRemote(nodeId) {
    const url = `/api/graph/nodes/${nodeId}?trimLanguageInfo=false`;
    return requestGet(url);
  }

  _renderSaveButton() {
    return (
      <Container textAlign="right">
        <Button
          type="submit"
          positive
          onClick={() => {
            this._onSaveTapped();
          }}
        >
          Save
        </Button>
      </Container>
    );
  }

  _saveNodeAndEdge() {
    return new Promise(async (resolve, reject) => {
      const created = this.state.meaningNode.nodeId === 0;
      const res = await request({
        method: created ? "post" : "put",
        url: created
          ? `/api/graph/nodes`
          : `/api/graph/nodes/${this.state.meaningNode.nodeId}`,
        data: this.state.meaningNode
      });

      const nodeId = this.state.bhajanNode.nodeId;
      const edges = this.state.bhajanNode.edges.filter(
        e => e.name !== "meaning"
      );
      edges.push({
        name: "meaning",
        nodeId: res.data.nodeId
      });
      const bhajanNode = Object.assign({}, this.state.bhajanNode);
      bhajanNode.edges = edges;
      const edgeRes = request({
        method: "put",
        url: `/api/graph/nodes/${this.state.bhajanNode.nodeId}`,
        data: bhajanNode
      });
      resolve(res.data.nodeId);
    });
  }

  _onSaveTapped() {
    this._saveNodeAndEdge().then(meaningNodeId => {
      this.props.history.push(`/nodes/${meaningNodeId}`);
    });
  }
}

function mapStateToProps(state, props) {
  return state;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(EditMarkdownNode);
